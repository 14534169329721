import Vue from 'vue/dist/vue.js'
import moment from 'moment'

Vue.component('publication', {
    props: ["p", "dateFormat"],
    template:
        '<div class="publication" v-if="p.files.length">'
            + "<span class='date'>{{ p.date | dateFormatter(dateFormat) }}</span>"
            + '<span class="divider"></span>'
            + '<span class="title">{{ p.title }}</span>'            
            + '<template v-for="f in p.files">'
                + '<span class="divider"></span>'
                + '<a :href="f.href" target="_blank">{{ f.name }}</a>'
            + '</template>'
        + '</div>',
    filters: {
        dateFormatter: function(value, format) {
            if (!value) {
                return '';
            }

            return moment(String(value)).format(format);
        }
    }
});




