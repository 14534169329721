import "babel-polyfill";
import axios from "axios";
import Vue from "vue/dist/vue.js";

import SmoothScroll from "./smooth-scroll.polyfills.min.js";

require("./blog-post.js");

var lang = new Vue({
    el: "#button-language",
    data: {
        languages: {
            cs: { location: "/", label: "ČESKY", lang: "cs" },
            en: { location: "/en/", label: "ENGLISH", lang: "en" },
        },
        originLanguage: "cs",
    },
    computed: {
        currentLanguage: function () {
            if (window.location.pathname !== "/") {
                return this.getPathnameLang();
            } else {
                return this.isForced() ? this.originLanguage : this.getNavigatorLang();
            }
        },
        nextLanguage: function () {
            return this.currentLanguage === this.originLanguage ? this.languages.en : this.languages.cs;
        },
    },
    methods: {
        getPathnameLang: function () {
            return window.location.pathname.toString().substring(1, 3);
        },
        getNavigatorLang: function () {
            return (navigator.language || navigator.userLanguage).split("-")[0];
        },
        getLanguageUrl: function (lang) {
            var url = window.location.origin + lang.location;
            if (this.getNavigatorLang() !== lang.lang) {
                url += "?force=true";
            }
            return url;
        },
        isForced: function () {
            return window.location.search.toString().includes("force=true");
        },
    },
    // mounted() {
    //     if (
    //         !this.isForced() &&
    //         this.currentLanguage !== this.originLanguage &&
    //         this.currentLanguage !== this.getPathnameLang()
    //     ) {
    //         window.location.replace(
    //             this.getLanguageUrl(this.languages[this.currentLanguage])
    //         );
    //     }
    // },
});

var blog = new Vue({
    el: "#blog",
    data: {
        posts: [],
    },
    computed: {
        blogUrl: function () {
            return "https://blog.econlab.cz";
        },
        apiUrl: function () {
            return this.blogUrl + "/ghost/api/v3/content";
        },
        apiClientSecret: function () {
            return "b89dbfd64842478a86d35ee33a";
        },
    },
    mounted() {
        this.getLastPosts();
    },
    methods: {
        getApiRequestUrl: function (endpoint, params) {
            var query = [];
            params["key"] = this.apiClientSecret;
            Object.keys(params).forEach((i) => query.push(encodeURIComponent(i) + "=" + encodeURIComponent(params[i])));
            //blog.econlab.cz/ghost/api/v3/content/posts/?key=b89dbfd64842478a86d35ee33a
            https: return this.apiUrl + "/" + endpoint + "/?" + query.join("&");
        },
        getLastPosts: function () {
            var vm = this;

            var url = this.getApiRequestUrl("posts", {
                limit: 3,
                fields: "title,html,feature_image,url",
            });

            axios
                .get(url)
                .then(function (response) {
                    vm.posts = [];
                    vm.posts.push.apply(vm.posts, response.data.posts);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
});

var scroll_mailto = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    clip: true,
    easing: "easeInOutCubic",
});

var scroll_down = new SmoothScroll("#button-down", {
    speed: 1000,
    clip: true,
    easing: "easeInOutCubic",
    offset: 33,
});

require("./publication.js");

var publications = new Vue({
    el: "#publikace",
    data: {
        publications: {
            cs: [
                {
                    date: "2024-11-04",
                    title: "Omluva Českomoravské komoditní burze Kladno",
                    files: [
                        {
                            name: "Document",
                            href: "https://docs.google.com/document/d/1wAwR1UeCM4JFuOpa5mgys37U53DtaDSjzTRGDvG8DUw/edit?tab=t.0",
                        },
                    ],
                },
		{
                    date: "2020-08-17",
                    title: "Místní dopad veřejných investic",
                    files: [
                        {
                            name: "Studie",
                            href: "https://econlab.cz/files/2020/08/econlab_lokalni_dodavatele_studie.pdf",
                        },
                        {
                            name: "Blog",
                            href: "https://blog.econlab.cz/zakazky-mistnim-dodavatelum/",
                        },
                    ],
                },
                {
                    date: "2020-07-13",
                    title: "Průzkum trhu",
                    files: [
                        {
                            name: "Metodika",
                            href: "https://blog.econlab.cz/pruzkum-trhu-jak-na-nej/",
                        },
                    ],
                },
                {
                    date: "2020-06-01",
                    title: "Výroční zpráva 2019",
                    files: [
                        {
                            name: "Dokument",
                            href: "/files/2020/06/econlab_vyrocni_zprava_2019.pdf",
                        },
                    ],
                },
                {
                    date: "2020-05-12",
                    title: "Centrální nákup roušek",
                    files: [
                        {
                            name: "Blog",
                            href: "https://blog.econlab.cz/stat-si-sam-zakazal-centralne-nakupovat-rousky/",
                        },
                    ],
                },
                {
                    date: "2020-04-14",
                    title: "Přezkum u VZMR",
                    files: [
                        {
                            name: "Metodika",
                            href: "https://blog.econlab.cz/prezkum-u-zakazek-maleho-rozsahu-coze/",
                        },
                    ],
                },
                {
                    date: "2020-04-02",
                    title: "Hodnocení slovenské státní správy",
                    files: [
                        {
                            name: "zindex.sk",
                            href: "https://www.zindex.sk/category/detail/STATNI_SPRAVA/10/",
                        },
                    ],
                },
                {
                    date: "2020-04-01",
                    title: "Uzavřená a Otevřená výzva",
                    files: [
                        {
                            name: "Metodika",
                            href: "https://blog.econlab.cz/otevrena-a-uzavrena-vyzva/",
                        },
                    ],
                },
                {
                    date: "2020-02-27",
                    title: "Metodika pro zakázky malého rozsahu",
                    files: [
                        {
                            name: "Metodika",
                            href: "/files/2020/02/Metodika-zadavani-verejnych-zakazek-maleho-rozsahu-final.docx",
                        },
                        {
                            name: "TZ",
                            href:
                                "/files/2020/02/TZ_Nova_pravidla_pro_male_zakazky_ulevi_obcim_ReSt_EconLab_Oživeni.docx",
                        },
                        {
                            name: "Blog",
                            href: "https://blog.zindex.cz/mala-revoluce-ve-verejnych-zakazkach/",
                        },
                    ],
                },
                {
                    date: "2019-11-06",
                    title: "Zrychlení ÚOHS",
                    files: [
                        {
                            name: "Studie",
                            href: "https://blog.econlab.cz/uohs-rozhoduje-nejrychleji-v-historii-i-diky-econlabu/",
                        },
                    ],
                },
                {
                    date: "2019-10-01",
                    title: "Hodnocení zadavatelů státní správy",
                    files: [
                        {
                            name: "zindex.cz",
                            href: "https://www.zindex.cz/category/detail/STATNI_SPRAVA/13/",
                        },
                    ],
                },
                {
                    date: "2019-07-22",
                    title: "Hodnotící kritéria ve veřejných zakázkách",
                    files: [
                        {
                            name: "Studie",
                            href: "/files/2019/07/2019-07-22-MPSV%20-%20studie%20kriteria.pdf",
                        },
                        {
                            name: "Prezentace",
                            href: "/files/2019/07/2019-07-22-Analýza%20kritérií%20-%20prezentace%20-%20MPSV.pdf",
                        },
                    ],
                },
                {
                    date: "2018-09-05",
                    title: "zIndex – žebříček měst ve veřejných zakázkách",
                    files: [
                        {
                            name: "TZ",
                            href: "/files/2014/10/2018-09-05-TZ-zindex-mesta-2015-2017-final.docx",
                        },
                        { name: "Web", href: "https://www.zindex.cz" },
                    ],
                },
                {
                    date: "2018-08-30",
                    title: "Suché období – analýza konkurence ve veřejných zakázkách",
                    files: [
                        {
                            name: "Studie",
                            href:
                                "https://medium.com/zindex/ve%C5%99ejn%C3%A9-zak%C3%A1zky-2018-such%C3%A9-obdob%C3%AD-b68a3b1f798b",
                        },
                    ],
                },
                {
                    date: "2018-01-22",
                    title: "Odhad velikostí prezidentských kampaní",
                    files: [
                        {
                            name: "Studie",
                            href: "/files/2018/01/2017-01-22-velikosti-prezidentskych-kampani.pdf",
                        },
                    ],
                },
                {
                    date: "2017-10-02",
                    title: "Vysvědčení politických stran",
                    files: [
                        {
                            name: "Výsledky",
                            href: "http://www.politickefinance.cz/",
                        },
                        {
                            name: "TZ",
                            href: "/files/2014/10/2017-10-02-TZ-hodnoceni-politickych-stran.docx",
                        },
                    ],
                },
                {
                    date: "2017-09-05",
                    title: "Metodika hodnocení politických stran",
                    files: [
                        {
                            name: "TZ",
                            href: "/files/2014/10/2017-09-05-TZ-hodnoceni-politickych-stran.docx",
                        },
                        {
                            name: "Metodika",
                            href: "/files/2014/10/Econlab-Metodika-hodnocení-financování-politických-stran.pdf",
                        },
                    ],
                },
                {
                    date: "2017-01-26",
                    title: "Mýty a fakta o rozhodování ÚOHS",
                    files: [
                        {
                            name: "TZ",
                            href: "/files/2014/10/2017-01-26-TZ-uohs.docx",
                        },
                        {
                            name: "Studie",
                            href: "/files/2014/10/2017-01-26-studie-uohs-final.pdf",
                        },
                    ],
                },
                {
                    date: "2016-10-04",
                    title: "Zpráva o financování politických stran 2012-2015",
                    files: [
                        {
                            name: "Studie",
                            href: "/files/2014/10/2016-10-04-financovani_politickych_stran.pdf",
                        },
                    ],
                },
                {
                    date: "2016-09-14",
                    title: "Hodnocení zakázek krajů",
                    files: [
                        {
                            name: "TZ",
                            href: "/files/2016/09/2016-09-14-TZ-zindex-kraje-2013-2015.docx",
                        },
                        { name: "Web", href: "http://www.zindex.cz/" },
                        {
                            name: "Data",
                            href: "/files/2014/10/2016-09-14-zindex-kraje-mesta-priloha-TZ.xlsx",
                        },
                    ],
                },
                {
                    date: "2016-04-29",
                    title: "Centrální zadávání v ČR",
                    files: [
                        {
                            name: "Studie",
                            href: "/files/2016/04/2016-04-28-Collaborative-Purchasing-in-Czech-Republic-final.pdf",
                        },
                    ],
                },
                {
                    date: "2016-02-15",
                    title: "Hodnocení zakkázek státních firem",
                    files: [
                        {
                            name: "TZ",
                            href: "/files/2014/10/2016-02-15-TZ-hodnoceni-zakazek-statnich-firem-final.docx",
                        },
                        { name: "Web", href: "http://www.zindex.cz/" },
                        {
                            name: "Data",
                            href: "/files/2016/02/zIndex_statni_firmy_2012-2014.xlsx",
                        },
                    ],
                },
                {
                    date: "2016-01-26",
                    title: "Zakázky bez soutěže 2015",
                    files: [
                        {
                            name: "Studie",
                            href: "/files/2016/01/2016-01-25-single-bids.pdf",
                        },
                    ],
                },
                {
                    date: "2015-12-09",
                    title: "Chyby na profilech zadavatele",
                    files: [
                        {
                            name: "Prohlášení",
                            href: "/files/2015/12/prohlaseni_uohs_econlab_oziveni.docx",
                        },
                    ],
                },
                {
                    date: "2015-11-23",
                    title: "Chyby na profilech zadavatele",
                    files: [
                        {
                            name: "TZ",
                            href: "/files/2015/11/2015-11-23-TZ-chyby-na-profilech-zadavatelu.docx",
                        },
                        { name: "Výsledky", href: "http://www.profily.info/" },
                        {
                            name: "Data",
                            href: "/files/2015/11/profily_zebricek.xlsx",
                        },
                    ],
                },
                {
                    date: "2015-09-18",
                    title: "Odhad dopadu pozměňovacích návrhů k zákonu o registru smluv",
                    files: [
                        {
                            name: "XLS",
                            href: "/files/2015/09/pozmenovaci_navrhy_odtemneni.xlsx",
                        },
                    ],
                },
                {
                    date: "2015-05-21",
                    title: "Financování politických stran 2014",
                    files: [
                        {
                            name: "Studie",
                            href:
                                "http://www.politickefinance.cz/documents/2015-05-20-report_financovani_politickych_stran_2014_final.pdf",
                        },
                    ],
                },
                {
                    date: "2015-04-28",
                    title: "zIndex: Hodnocení měst podle dobré praxe ve veřejných zakázkách – studie",
                    files: [
                        {
                            name: "EN",
                            href: "/files/2014/10/zIndex-for-municipalities-study.pdf",
                        },
                    ],
                },
                {
                    date: "2015-04-06",
                    title: "Fact sheet k financování politických stran",
                    files: [
                        {
                            name: "Materiál",
                            href: "/files/2014/10/2015-04-06-polfin-factsheet.pdf",
                        },
                    ],
                },
                {
                    date: "2015-03-21",
                    title: "Stanovisko Transparency, Oživení a CAE k novému zákonu o zadávacích řízeních",
                    files: [
                        {
                            name: "Stanovisko",
                            href: "/files/2014/10/Zákon-o-zadávacích-rizenich-stanovisko-20_3_2015.pdf",
                        },
                    ],
                },
                {
                    date: "2015-03-04",
                    title: "Stínový výpočet RIA k registru smluv",
                    files: [
                        {
                            name: "Studie",
                            href: "/files/2014/10/2015-03-04-stinovy-vypocet-ria-k-registru-smluv.pdf",
                        },
                    ],
                },
                {
                    date: "2014-11-28",
                    title: "Financování politických stran",
                    files: [
                        {
                            name: "Studie (úplná)",
                            href: "/files/2014/11/2014-11-27-studie_dary_politickym_stranam_final.pdf",
                        },
                        {
                            name: "TZ",
                            href: "/files/2014/12/2014-11-27-tz-dary-politickym-stranam-uoou.pdf",
                        },
                        {
                            name: "Web",
                            href: "http://www.politickefinance.cz/",
                        },
                    ],
                },
                {
                    date: "2014-10-15",
                    title: "Hodnocení zakázek měst 2011-2013 zIndex",
                    files: [
                        {
                            name: "Prezentace",
                            href: "/files/2014/10/2014-15-10-zIndex_prezentace_final.pdf",
                        },
                        {
                            name: "TZ",
                            href: "/files/2014/10/2014-15-10-tz-zindex-hodnoceni-zakazek-mest-2011-2013.pdf",
                        },
                        { name: "Web", href: "http://www.zindex.cz/" },
                    ],
                },
                {
                    date: "2014-09-04",
                    title: "Firemní dary politickým stranám",
                    files: [
                        {
                            name: "Studie (dílčí)",
                            href: "/files/old/2014-09-04-studie_dary_politickym_stranam_po.pdf",
                        },
                        {
                            name: "TZ",
                            href: "/files/old/2014-09-04-tz-dary-politickym-stranam-po.pdf",
                        },
                    ],
                },
                {
                    date: "2014-06-19",
                    title: "Hromadné nedokládání vlastníků žadatelů o dotace",
                    files: [
                        {
                            name: "TZ",
                            href: "/files/old/2014-06-19-tz-dotace-bez-dolozeni-vlastniku-mohou-byt-protipravni.pdf",
                        },
                        {
                            name: "Doporučení",
                            href: "/files/old/principy_dokladani_vlastniku_podle_zakona_218.pdf",
                        },
                    ],
                },
                {
                    date: "2014-06-11",
                    title: "Kontrola zveřejňování smluv na profilech zadavatele",
                    files: [
                        {
                            name: "TZ",
                            href: "/files/old/TZ_5procent_smluv_chybi_na_profilech_zadavatele.pdf",
                        },
                        {
                            name: "Data",
                            href: "ffiles/old/publikace_smluv_na_profilech.xlsx",
                        },
                    ],
                },
                {
                    date: "2014-02-20",
                    title: "Studie o vlivu novely zákona na útlum trhu VZ",
                    files: [
                        {
                            name: "Studie",
                            href: "/files/old/2014-02-20-studie-objem-zakazek.pdf",
                        },
                    ],
                },
                {
                    date: "2013-11-05",
                    title: "S AV ČR spouštíme web o financování politických stran",
                    files: [
                        {
                            name: "Web",
                            href: "http://www.politickefinance.cz/",
                        },
                    ],
                },
                {
                    date: "2013-09-11",
                    title: "Veřejné podniky a politická kontrola",
                    files: [
                        {
                            name: "Studie",
                            href: "/files/2017/11/2013-09-10-SOEs-studie.pdf",
                        },
                    ],
                },
                {
                    date: "2013-05-28",
                    title: "Případová studie Česká pošta",
                    files: [
                        {
                            name: "Příklad špatné praxe",
                            href: "/files/old/2013-05-27-ceska-posta-position-paper.pdf",
                        },
                    ],
                },
                {
                    date: "2013-04-15",
                    title: "Platy ve státní správě",
                    files: [
                        {
                            name: "Analýza (k zákonu o úřednících)",
                            href: "/files/old/Analyza-platy-ve-statni-sprave-zakon-o-urednicich.pdf",
                        },
                    ],
                },
                {
                    date: "2013-03-11",
                    title: "Start projektu Rekonstrukce státu, na kterém se podílíme",
                    files: [
                        {
                            name: "Web",
                            href: "http://www.rekonstrukcestatu.cz/",
                        },
                    ],
                },
                {
                    date: "2013-02-21",
                    title: "Rozkrývání dodavatelů veřejných zakázek",
                    files: [
                        {
                            name: "Studie",
                            href: "/files/2014/12/Rozkryvani-studie.pdf",
                        },
                    ],
                },
                {
                    date: "2013-01-22",
                    title: "Nový nástroj pro férové zveřejňování zakázek",
                    files: [{ name: "zInfo.cz", href: "http://www.zinfo.cz/" }],
                },
                {
                    date: "2012-06-20",
                    title: "Spolu s Oživení,o.s. startujeme portál Všechnyzakázky.cz",
                    files: [
                        {
                            name: "Oživení,o.s.",
                            href: "http://www.bezkorupce.cz/",
                        },
                        {
                            name: "Všechnyzakázky.cz",
                            href: "http://www.vsechnyzakazky.cz/",
                        },
                    ],
                },
                {
                    date: "2012-04-13",
                    title: "Založili jsme sesterskou společnost pro komerční práci s daty Datlab s.r.o.",
                    files: [{ name: "Web", href: "http://www.datlab.cz/" }],
                },
                {
                    date: "2011-10-15",
                    title: "Přehled hospodaření státních podniků (spolu s EPS)",
                    files: [{ name: "Studie", href: "/files/old/SOEs-final.pdf" }],
                },
                {
                    date: "2011-05-18",
                    title: "Akcie na majitele ve veřejných zakázkách 2007-2010",
                    files: [
                        {
                            name: "Článek",
                            href: "http://blog.aktualne.centrum.cz/blogy/jiri-skuhrovec.php?itemid=13160",
                        },
                    ],
                },
                {
                    date: "2011-03-07",
                    title: "IKEM – zakázka neznámé firmě za 437 mil.",
                    files: [
                        {
                            name: "Wiki",
                            href: "http://wiki.zindex.cz/doku.php?id=ikem_-_zakazka_nezname_firme_za_437_mil",
                        },
                    ],
                },
                {
                    date: "2011-03-03",
                    title: "Konec losovaček v čechách?",
                    files: [
                        {
                            name: "Článek",
                            href: "http://blog.aktualne.centrum.cz/blogy/jiri-skuhrovec.php?itemid=12410",
                        },
                    ],
                },
                {
                    date: "2011-01-25",
                    title: "pilotní publikace hodnocení zadavatelů zIndex",
                    files: [
                        {
                            name: "Prezentace",
                            href: "/files/old/110126-ZINDEX-MEDIA.pdf",
                        },
                    ],
                },
                {
                    date: "2010-06-01",
                    title:
                        "J. Chvalkovská, J. Skuhrovec : Measuring transparency in public spending: Case of Czech Public e-Procurement Information System (první verze metodiky hodnocení zIndex, anglicky)",
                    files: [
                        {
                            name: "Článek",
                            href: "http://ies.fsv.cuni.cz/sci/publication/show/id/4063/lang/cs",
                        },
                    ],
                },
            ],
            en: [
                {
                    date: "2024-11-04",
                    title: "Omluva Českomoravské komoditní burze Kladno",
                    files: [
                        {
                            name: "Document",
                            href: "https://docs.google.com/document/d/1wAwR1UeCM4JFuOpa5mgys37U53DtaDSjzTRGDvG8DUw/edit?tab=t.0",
                        },
                    ],
                },
		{
                    date: "2020-08-17",
                    title: "Local impact of public investment",
                    files: [
                        {
                            name: "Study",
                            href: "https://econlab.cz/files/2020/08/econlab_lokalni_dodavatele_studie.pdf",
                        },
                        {
                            name: "Blog",
                            href: "https://blog.econlab.cz/zakazky-mistnim-dodavatelum/",
                        },
                    ],
                },
                {
                    date: "2020-07-13",
                    title: "Market research",
                    files: [
                        {
                            name: "Methodology",
                            href: "https://blog.econlab.cz/pruzkum-trhu-jak-na-nej/",
                        },
                    ],
                },
                {
                    date: "2020-06-01",
                    title: "Annual report 2019",
                    files: [
                        {
                            name: "Document",
                            href: "/files/2020/06/econlab_vyrocni_zprava_2019.pdf",
                        },
                    ],
                },
                {
                    date: "2020-05-12",
                    title: "Face masks central purchases",
                    files: [
                        {
                            name: "Blog",
                            href: "https://blog.econlab.cz/stat-si-sam-zakazal-centralne-nakupovat-rousky/",
                        },
                    ],
                },
                {
                    date: "2020-04-14",
                    title: "Small tenders oversight",
                    files: [
                        {
                            name: "Methodology",
                            href: "https://blog.econlab.cz/prezkum-u-zakazek-maleho-rozsahu-coze/",
                        },
                    ],
                },
                {
                    date: "2020-04-01",
                    title: "Closed and open calls",
                    files: [
                        {
                            name: "Methodology",
                            href: "https://blog.econlab.cz/otevrena-a-uzavrena-vyzva/",
                        },
                    ],
                },
                {
                    date: "2020-02-04",
                    title: "Slovak public buyer rating - government",
                    files: [
                        {
                            name: "zindex.sk",
                            href: "https://www.zindex.sk/category/detail/STATNI_SPRAVA/10/",
                        },
                    ],
                },
                {
                    date: "2019-11-06",
                    title: "Competition office speed up",
                    files: [
                        {
                            name: "Study",
                            href: "https://blog.econlab.cz/uohs-rozhoduje-nejrychleji-v-historii-i-diky-econlabu/",
                        },
                    ],
                },
                {
                    date: "2019-10-01",
                    title: "Czech public buyer rating - government",
                    files: [
                        {
                            name: "zindex.cz",
                            href: ".cz	 https://www.zindex.cz/category/detail/STATNI_SPRAVA/13/",
                        },
                    ],
                },
                {
                    date: "2019-07-22",
                    title: "Award criteria in public procurement",
                    files: [
                        {
                            name: "Study (CZ)",
                            href: "/files/2019/07/2019-07-22-MPSV%20-%20studie%20kriteria.pdf",
                        },
                        {
                            name: "Blog",
                            href: "https://blog.datlab.eu/can-government-pick-quality-supplier/",
                        },
                    ],
                },
                {
                    date: "2018-09-05",
                    title: "zIndex – municipalities benchmarking",
                    files: [
                        {
                            name: "Press release",
                            href: "/files/2014/10/2018-09-05-TZ-zindex-mesta-2015-2017-final.docx",
                        },
                        { name: "Web", href: "https://www.zindex.cz/" },
                    ],
                },
                {
                    date: "2018-08-30",
                    title: "Dry season: analysis of competition for public procurement",
                    files: [
                        {
                            name: "Study",
                            href:
                                "https://medium.com/zindex/ve%C5%99ejn%C3%A9-zak%C3%A1zky-2018-such%C3%A9-obdob%C3%AD-b68a3b1f798b",
                        },
                    ],
                },
                {
                    date: "2018-01-22",
                    title: "Estimate of Presidential Campaign Sizes",
                    files: [
                        {
                            name: "Study",
                            href: "/files/2018/01/2017-01-22-velikosti-prezidentskych-kampani.pdf",
                        },
                    ],
                },
                {
                    date: "2017-10-02",
                    title: " Rating of political parties",
                    files: [
                        {
                            name: "Results",
                            href: "http://www.politickefinance.cz/en/",
                        },
                        {
                            name: "Press release",
                            href: "/files/2014/10/2017-10-02-TZ-hodnoceni-politickych-stran.docx",
                        },
                    ],
                },
                {
                    date: "2017-09-05",
                    title: "Metodika hodnocení politických stran",
                    files: [
                        {
                            name: "Press release",
                            href: "/files/2014/10/2017-09-05-TZ-hodnoceni-politickych-stran.docx",
                        },
                        {
                            name: "Methodology",
                            href: "/files/2014/10/Econlab-Metodika-hodnocení-financování-politických-stran.pdf",
                        },
                    ],
                },
                {
                    date: "2017-01-26",
                    title: "Myths and Facts on Remedies in Public Procurement",
                    files: [
                        {
                            name: "Study",
                            href: "/files/2014/10/2017-01-26-studie-uohs-final.pdf",
                        },
                    ],
                },
                {
                    date: "2016-10-04",
                    title: "Political Party Financing Report 2012-2015",
                    files: [
                        {
                            name: "Studie",
                            href: "/files/2014/10/2016-10-04-financovani_politickych_stran.pdf",
                        },
                    ],
                },
                {
                    date: "2016-09-14",
                    title: "Benchmarking procurement in Regions",
                    files: [
                        {
                            name: "Press release",
                            href: "/files/2016/09/2016-09-14-TZ-zindex-kraje-2013-2015.docx",
                        },
                        { name: "Web", href: "http://www.zindex.cz/" },
                        {
                            name: "Data",
                            href: "/files/2014/10/2016-09-14-zindex-kraje-mesta-priloha-TZ.xlsx",
                        },
                    ],
                },
                {
                    date: "2016-04-29",
                    title: "Central purchasing in Czech Republic",
                    files: [
                        {
                            name: "Study",
                            href: "/files/2016/04/2016-04-28-Collaborative-Purchasing-in-Czech-Republic-final.pdf",
                        },
                    ],
                },
                {
                    date: "2016-02-15",
                    title: "Benchmarking procurement in state-owned enterprises",
                    files: [
                        {
                            name: "Press release",
                            href: "/files/2014/10/2016-02-15-TZ-hodnoceni-zakazek-statnich-firem-final.docx",
                        },
                        { name: "Web", href: "http://www.zindex.cz/" },
                        {
                            name: "Data",
                            href: "/files/2016/02/zIndex_statni_firmy_2012-2014.xlsx",
                        },
                    ],
                },
                {
                    date: "2016-01-26",
                    title: "Single-bid procurement 2015",
                    files: [
                        {
                            name: "Study",
                            href: "/files/2016/01/2016-01-25-single-bids-en.pdf",
                        },
                    ],
                },
                {
                    date: "2015-12-09",
                    title: "Mistakes on Buyers’ profiles",
                    files: [
                        {
                            name: "Statement",
                            href: "/files/2015/12/prohlaseni_uohs_econlab_oziveni.docx",
                        },
                    ],
                },
                {
                    date: "2015-11-23",
                    title: "Mistakes on Buyers’ profiles",
                    files: [
                        {
                            name: "Press release",
                            href: "/files/2015/11/2015-11-23-TZ-chyby-na-profilech-zadavatelu.docx",
                        },
                        { name: "Web", href: "http://www.profily.info/" },
                        {
                            name: "Data",
                            href: "/files/2015/11/profily_zebricek.xlsx",
                        },
                    ],
                },
                {
                    date: "2015-09-18",
                    title: "Impact assesment of ammendments to the Act on Contracts Regsitry",
                    files: [
                        {
                            name: "XLS",
                            href: "/files/2015/09/pozmenovaci_navrhy_odtemneni.xlsx",
                        },
                    ],
                },
                {
                    date: "2015-05-21",
                    title: "Political Party Financing 2014",
                    files: [
                        {
                            name: "Report",
                            href:
                                "http://www.politickefinance.cz/documents/2015-05-20-report_financovani_politickych_stran_2014_final.pdf",
                        },
                    ],
                },
                {
                    date: "2015-04-28",
                    title: "zIndex: Benchmarking of municipality procurement – study",
                    files: [
                        {
                            name: "EN",
                            href: "/files/2014/10/zIndex-for-municipalities-study.pdf",
                        },
                    ],
                },
                {
                    date: "2015-04-06",
                    title: "Fact sheet on political party financing",
                    files: [
                        {
                            name: "Paper",
                            href: "/files/2014/10/2015-04-06-polfin-factsheet.pdf",
                        },
                    ],
                },
                {
                    date: "2015-03-21",
                    title: "Joint NGO position to new procurement law",
                    files: [
                        {
                            name: "Paper",
                            href: "/files/2014/10/Zákon-o-zadávacích-rizenich-stanovisko-20_3_2015.pdf",
                        },
                    ],
                },
                {
                    date: "2015-03-04",
                    title: "Shadow RIA calculation for public contracts registry",
                    files: [
                        {
                            name: "Study",
                            href: "/files/2014/10/2015-03-04-stinovy-vypocet-ria-k-registru-smluv.pdf",
                        },
                    ],
                },
                {
                    date: "2014-11-28",
                    title: "Financing of political parties (EN)",
                    files: [
                        {
                            name: "Paper",
                            href: "/files/2015/03/2015-01-12-Analysis-of-Czech-Political-Party-Donations.pdf",
                        },
                        {
                            name: "Press release",
                            href: "/files/2015/01/Who-finances-politics-in-Czech-republic.pdf",
                        },
                        {
                            name: "Web",
                            href: "http://www.politickefinance.cz/en/",
                        },
                    ],
                },
                {
                    date: "2014-10-15",
                    title: "Municipalities procurement rating 2011-2013 zIndex",
                    files: [
                        {
                            name: "Prezentace",
                            href: "/files/2014/10/2014-15-10-zIndex_prezentace_final.pdf",
                        },
                        {
                            name: "Press release",
                            href: "/files/2014/10/2014-15-10-tz-zindex-hodnoceni-zakazek-mest-2011-2013.pdf",
                        },
                        { name: "Web", href: "http://www.zindex.cz/" },
                    ],
                },
                {
                    date: "2014-09-04",
                    title: "Corporate Donations to Political Parties",
                    files: [
                        {
                            name: "Study",
                            href: "/files/old/2014-09-04-studie_dary_politickym_stranam_po.pdf",
                        },
                        {
                            name: "Press release",
                            href: "/files/old/2014-09-04-tz-dary-politickym-stranam-po.pdf",
                        },
                    ],
                },
                {
                    date: "2014-06-19",
                    title: "How EU donation recievers break law by not disclosing beneficial owners",
                    files: [
                        {
                            name: "Press release",
                            href: "/files/old/2014-06-19-tz-dotace-bez-dolozeni-vlastniku-mohou-byt-protipravni.pdf",
                        },
                        {
                            name: "Recommendations",
                            href: "/files/old/principy_dokladani_vlastniku_podle_zakona_218.pdf",
                        },
                    ],
                },
                {
                    date: "2014-02-20",
                    title: "On effect of legal changes on Czech procurement volume and performance",
                    files: [
                        {
                            name: "Study",
                            href: "/files/old/2014-02-20-studie-objem-zakazek.pdf",
                        },
                    ],
                },
                {
                    date: "2013-11-05",
                    title: "We launch website mapping Political party financing",
                    files: [
                        {
                            name: "Web",
                            href: "http://www.politickefinance.cz/en/",
                        },
                    ],
                },
                {
                    date: "2013-09-11",
                    title: "Political control of SOE’s",
                    files: [
                        {
                            name: "Study",
                            href: "/files/2017/11/2013-09-10-SOEs-studie.pdf",
                        },
                    ],
                },
                {
                    date: "2012-06-20",
                    title:
                        "With colleagues from Oživení,o.s. we start portal mapping Czech public procurement Všechnyzakázky.cz",
                    files: [
                        {
                            name: "Oživení,o.s.",
                            href: "http://www.bezkorupce.cz/english",
                        },
                        {
                            name: "Všechnyzakázky.cz",
                            href: "http://www.vsechnyzakazky.cz/en/",
                        },
                    ],
                },
                {
                    date: "2012-04-13",
                    title: "Založili jsme sesterskou společnost pro komerční práci s daty Datlab s.r.o.",
                    files: [{ name: "Web", href: "http://www.datlab.cz/" }],
                },
                {
                    date: "2011-10-15",
                    title: "Czech SOE’s performance overview",
                    files: [{ name: "Paper", href: "/files/old/SOEs-final.pdf" }],
                },
                {
                    date: "2011-05-18",
                    title: "Bearer shares profitability in public procurement 2007-2010",
                    files: [
                        {
                            name: "Blog",
                            href: "http://blog.aktualne.centrum.cz/blogy/jiri-skuhrovec.php?itemid=13160",
                        },
                    ],
                },
                {
                    date: "2011-01-25",
                    title: "pilot zIndex rating of Czech ministries",
                    files: [
                        {
                            name: "Prezentation",
                            href: "/files/old/110126-ZINDEX-MEDIA.pdf",
                        },
                    ],
                },
                {
                    date: "2010-06-01",
                    title:
                        "J. Chvalkovská, J. Skuhrovec : Measuring transparency in public spending: Case of Czech Public e-Procurement Information System (first draft zIndex methodology zIndex)",
                    files: [
                        {
                            name: "Článek",
                            href: "http://ies.fsv.cuni.cz/sci/publication/show/id/4063/lang/cs",
                        },
                    ],
                },
            ],
        },
        showRecent: true,
        recentCount: 10,
        views: {
            recent: {
                value: true,
                title: { cs: "Poslední publikace", en: "Last publications" },
            },
            all: {
                value: false,
                title: { cs: "Všechny publikace", en: "All publications" },
            },
        },
        lang: "cs",
    },
    created: function () {
        // current language from lang vue app (see above)
        this.lang = lang.currentLanguage;
    },
    computed: {
        nextView: function () {
            return this.showRecent ? this.views.all : this.views.recent;
        },
        sortedPublications: function () {
            // descending order by publication date
            return this.publications[this.lang].sort((a, b) => b.date.localeCompare(a.date));
        },
        recentPublications: function () {
            return this.sortedPublications.slice(0, this.recentCount);
        },
    },
    methods: {
        getPublications: function () {
            return this.showRecent ? this.recentPublications : this.sortedPublications;
        },
    },
});
